import * as React from "react";
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { GatsbySeo, BlogPostJsonLd } from "gatsby-plugin-next-seo";
import { Button, Container } from 'react-bootstrap';

import './blog-post.css';
import { MDXRenderer } from "gatsby-plugin-mdx";

const BlogPostLayout = ({ data }) => {
  const url = data.site.siteMetadata.siteUrl + data.graphCmsBlogPost.slug;

  return (
    <Layout title={data.graphCmsBlogPost.title} description={data.graphCmsBlogPost.description}>
      <GatsbySeo
        openGraph={{
          title: data.graphCmsBlogPost.title,
          description: data.graphCmsBlogPost.description,
          url,
          type: 'article',
          article: {
            publishedTime: data.graphCmsBlogPost.date,
            tags: data.graphCmsBlogPost.tags
          }
        }}
      />
      <BlogPostJsonLd
        url={url}
        title={data.graphCmsBlogPost.title}
        dataPublished={data.graphCmsBlogPost.date}
        authorName={data.site.siteMetadata.author}
        description={data.graphCmsBlogPost.description}
      />

      <section className="bg-light">
        <Container>
          <h1>{data.graphCmsBlogPost.title}</h1>
          <p className="lead mb-0">{(new Date(data.graphCmsBlogPost.date)).toLocaleDateString()}</p>
        </Container>
      </section>

      <section>
        <Container className="post">
          <MDXRenderer>
            {data.graphCmsBlogPost.content.markdownNode.childMdx.body}
          </MDXRenderer>

          <div id="attachments" className="d-grid d-sm-block">
            {data.graphCmsBlogPost.attachments.map((ref) => (
              <Button key={ref.id} href={ref.url} variant="primary" size="lg" className="m-2">
                Download {ref.displayName || ref.fileName}
              </Button>
            ))}
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    graphCmsBlogPost(id: {eq: $id}) {
      slug
      title
      date
      description
      content {
        markdownNode {
          childMdx {
            body
          }
        }
      }
      attachments {
        id
        displayName
        fileName
        url
      }
      tags
    }
    site {
      siteMetadata {
        siteUrl
        author
      }
    }
  }
`


export default BlogPostLayout
